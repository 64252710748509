<template>
	<div dark>
		<h3>Admin</h3>

		<v-data-table  v-if="items"
			:headers="headers"
			:items="items"
			:items-per-page="10"
			class="elevation-1"
		></v-data-table>


		<!-- <div v-for="bier in biertjes" :key="bier.id">
			{{ bier.naam }}
		</div> -->
	</div>
</template>

<script>
//import axios from "axios";
import { getBeers } from "@/api/bier.js";

export default {
	name: "Bier",

	data: () => ({
		items: [],        
		headers: [],
	}),

	created() {
        getBeers().then((response) => {
            console.log("getBeers from Beer.vue. data:", response.data.data);
			this.items = response.data.data;
            this.headers = Object.keys(this.items[0]);
            //console.log("this.headers 1:", this.headers);

            this.headers = this.headers.map((val) => {
                return {"text": val, "value":val};
			});
            //console.log("this.headers 2:",this.headers);
        });
	},
    methods: {
		// testje() {
		// 	getBeers().then((response) => {
		// 		console.log("getBeers. data:", response.data.data);
		// 	});
		// },
	},
};
</script>

